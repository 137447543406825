import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import ro from '../images/ro.png'
import ml1 from '../images/ml1.svg'
import ml2 from '../images/ml2.svg'
import ml3 from '../images/ml3.svg'
import ml4 from '../images/ml4.svg'
import RO_colours from '../images/RO_colours.png'
import RO_logo from '../images/RO_logo.png'
import ro_final from '../videos/ro_final.mp4'
import up_arrow from '../images/up_arrow.svg'
import Article1 from '../images/Article1.jpg'
import Article2 from '../images/Article2.jpg'
import Article3 from '../images/Article3.jpg'
import RO_photography1 from '../images/RO_photography1.jpg'
import RO_photography2 from '../images/RO_photography2.jpg'
import RO_photography3 from '../images/RO_photography3.jpg'
import SEO from '../components/seo'

function rootedobjects() {
    return (
        <StyledWrapper>
            <Layout>
                <SEO title="Rooted Objects Case Study" description="ooted Objects was our opportunity to design and develop a new platform that embodies a distinct
                    sense of simplicity in an otherwise noisy world of fashion and retail."
                    path="/rootedobjects"
                     pageImage={ro}
                />
                <div className="rooted">
                    <div className="rooted__background">
                        <div className="rooted__main">
                            <div className="rooted__herotext">
                                <h5>CASE STUDY</h5>
                                <hr className="rooted__line"/>
                                <div className="rooted__title">
                                    <h5>ROOTED OBJECTS</h5>
                                    <div className="case__study__page__title d-sm-block d-none">
                                        Creating a marketplace with a distinct sense of simplicity
                                    </div>
                                </div>
                            </div>
                            <img src={ro} alt="Rooted Objects hero image"/>
                            <div className="page__titleMobile d-sm-none d-block mt-4">
                                Creating a marketplace with a distinct sense of simplicity
                            </div>
                            <div className="row rooted__details">
                                <div className="rooted__store col-sm-6 col-12">
                                    <h5>Rooted Objects was our opportunity to design and develop a new platform 
                                        that embodies a distinct sense of simplicity in an otherwise noisy world of 
                                        fashion and retail. It was important to Rooted Objects that we create a 
                                        marriage of long-form editorial content, snackable content, and commerce. 
                                        The distinct taste for minimalism, responsibility and design became the epicenter 
                                        for the interface design and user experience
                                    </h5>
                                    <a href="https://rootedobjects.com/" target="_blank">VIEW WEBSITE</a>
                                </div>
                                
                                <div className="rooted__tools col-sm-6 col-12">
                                    <span>
                                    <h4>What We Did:</h4>
                                    <h5>Content, Design, Development, Branding & Print</h5>
                                    </span>
                                    <span>
                                    <h4>Built With:</h4>
                                    <h5>Magento 2.0, Vue Storefront</h5>
                                    </span>
                                    <span>
                                    <h4>PWA Built In Partnership With:</h4>
                                    <h5><a href="https://aureatelabs.com/case-studies/rooted-objects-magento-2-pwa/" target="_blank">Aurate Labs</a></h5>
                                    </span>
                                    <span>
                                    <h4>Photography in Partnership With:</h4>
                                    <h5><a href="https://www.instagram.com/_meister_meister_/" target="_blank">Gokul Rao Kadam</a></h5>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="context">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-4">
                                    <div className="number-text-block">
                                        <p className="cs-number">1</p>
                                        <hr className="cs-hr-line"/>
                                        <h5 className="cs-subtext">THE OPPORTUNITY</h5>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8 cs-left-right-border">
                                    <h5 className="mb-4">Increasing recognition of the issues with fast and cheap 
                                    fashion has led to a wave of remarkable responsible brands. These creators invest 
                                    thought/research in sustainable processes, materials and communities and create objects that are 
                                    important, relevant and desirable. They don’t design for trends but instead for unique 
                                    personalities and different states of mind. Rooted Objects is a platform for such creators and brands.
                                    </h5>
                                    <div className="container">
                                        <div className="row mb-2 rooted__context">
                                            <div className="col-3">
                                                <img src={ml2} alt=""/>
                                            </div>
                                            <div className="col-9 mb-4">
                                                <h5>“I am looking for ways to support local and sustainability-minded brands but I honestly don’t know how”</h5>
                                            </div>
                                        </div>
                                        <div className="row mb-2 rooted__context">
                                            <div className="col-3">
                                                <img src={ml4} alt=""/>
                                            </div>
                                            <div className="col-9 mb-4">
                                                <h5>“Most organic alternatives don’t look or taste as good as the regular stuff.”</h5>
                                            </div>
                                        </div>
                                        <div className="row mb-2 rooted__context">
                                            <div className="col-3">
                                                <img src={ml1} alt=""/>
                                            </div>
                                            <div className="col-9 mb-4">
                                                <h5>“I don’t buy clothing online, because I don’t know how it’ll actually look and fit when it arrives…”</h5>
                                            </div>
                                        </div>
                                        <div className="row mb-2 rooted__context">
                                            <div className="col-3">
                                                <img src={ml3} alt=""/>
                                            </div>
                                            <div className="col-9 mb-4">
                                                <h5>“I don’t know if the brand is actually doing what they say they do…”</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cs-two">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 col-md-8">
                                    <div className="row">
                                        <div className="col-12">
                                            <img className="cs-two__image" src={RO_logo}  alt=""/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-5">
                                            <img className="cs-two__image" src={RO_colours} alt=""/>
                                            <p>slow, cyclical, of the earth, minimal, contemporary </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <div className="number-text-block">
                                        <p className="cs-number">2</p>
                                        <hr className="cs-hr-line"/>
                                        <p className="cs-subtext">CONCEPTUALIZATION</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cs-three">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-4">
                                    <div className="number-text-block">
                                        <p className="cs-number">3</p>
                                        <hr className="cs-hr-line"/>
                                        <p className="cs-subtext">CONTENT</p>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8 cs-left-right-border">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-4">
                                                <img className="cs-three__img" src={RO_photography1} alt=""/>
                                            </div>
                                            <div className="col-4">
                                                <img className="cs-three__img" src={RO_photography2} alt=""/>
                                            </div>
                                            <div className="col-4">
                                                <img className="cs-three__img" src={RO_photography3} alt=""/>
                                            </div>
                                            <h5 className="cs-small-text">Photography</h5>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-4">
                                                <img className="cs-three__article" src={Article1} alt=""/>
                                            </div>
                                            <div className="col-4">
                                                <img className="cs-three__article" src={Article2} alt=""/>
                                            </div>
                                            <div className="col-4">
                                                <img className="cs-three__article" src={Article3} alt=""/>
                                            </div>
                                            <h5 className="cs-small-text">Article</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <video autoplay="autoplay" loop="loop" muted="muted" playsinline="" className="showreel w-100" src={ro_final}></video>
                    <div className="callouts">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 col-md-8 callouts__list">
                                    <h5>Callouts:</h5>
                                    <ol>
                                        <li>Fast, mobile-first performance with PWA</li>
                                        <li>Vendors Panel for Inventory Management</li>
                                        <li>Custom sizing option for couture segment</li>
                                        <li>Shoppable articles, better content-shopping integration</li>
                                        <li>Easy to update landing pages</li>
                                        <li>Promotional spots on multiple templates</li>
                                        <li>Single Page Checkout</li>
                                    </ol>
                                </div>
                                <div className="col-lg-3 col-md-4 cs-left-right-border">
                                    <div className="number-text-block">
                                        <p className="cs-number">4</p>
                                        <hr className="cs-hr-line"/>
                                        <p className="cs-subtext">UX/UI DESIGN & TECHNOLOGY</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cs-five">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-4">
                                    <div className="number-text-block">
                                        <p className="cs-number">5</p>
                                        <hr className="cs-hr-line"/>
                                        <p className="cs-subtext">HIGHLIGHTS</p>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8 cs-left-right-border">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <p>VERSIONS DEVELOPED</p>
                                                <div className="cs-up-arrow">
                                                    <h1>3</h1>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <p>V3. CONVERSIONS</p>
                                                <div className="cs-up-arrow">
                                                    <h1>162%</h1>
                                                    <img src={up_arrow} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <p>PAGE/SESSIONS</p>
                                                <div className="cs-up-arrow">
                                                    <h1>34%</h1>
                                                    <img src={up_arrow} alt=""/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <p>BOUNCE RATE</p>
                                                <div className="cs-down-arrow">
                                                    <h1>14%</h1>
                                                    <img className="down-arrow" src={up_arrow} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <p>PAGE VIEWS</p>
                                                <div className="cs-up-arrow">
                                                    <h1>68%</h1>
                                                    <img src={up_arrow} alt=""/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <p>V3. CHECKOUTS</p>
                                                <div className="cs-up-arrow">
                                                    <h1>311%</h1>
                                                    <img src={up_arrow} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </StyledWrapper>
        
    )
}

export default rootedobjects

const StyledWrapper = styled.div`
  .rooted {
    transition: 750ms;
    height: 100%;
  }

  .rooted__background {
    background: #e3e3e3;
  }

  .rooted__main {
    padding: 150px 100px 100px 100px;
  }

  .rooted__herotext {
    display: flex;
    width: 100%;
    padding-bottom: 100px;
  }

  .rooted__herotext > h5 {
    font-weight: 600;
    color: black;
    width: 15%;
  }

  .rooted__title > h5 {
    font-weight: 600;
    color: black;
  }

  .rooted__title {
    padding-left: 30px;
  }

  .rooted__herotext > hr {
    border: 1px solid black;
    width: 30%;
  }

  .rooted__main > img {
    width: 100%;
    object-fit: cover;
    margin: 0px;
  }

  .rooted__line {
    width: 45%;
  }

  .rooted__details {
    padding: 50px 15px 0px 100px;
  }

  .rooted__store > h5, .rooted__tools > span > h5, h4 {
    color: black;
  }

  .rooted__store > h5, .rooted__tools > span > h5 {
    margin-bottom: 32px;
    font-weight: 300;
  }

  .rooted__tools > h5 {
    display: flex;
    flex-direction: column;
  }

  .rooted__tools > span > h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
  }

  .rooted__tools > span > h5 > a {
    font-size: 18px !important;
  }

  .context {
    margin-top: 100px;
  }

  .number-text-block {
    text-align: center;
    margin-top: 50px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 30px;
    order: 1;
  }

  .cs-left-right-border {
    border-left: solid 2px #F6F6F6
  }

  .cs-number {
    color: #3e363d;
    font-size: 130px;
    font-weight: 700;
    line-height: 33px;
    padding-bottom: 30px;
  }

  .cs-hr-line {
    background-color: #4A4BC7;
    max-width: 100%;
    width: 200px;
  }

  .rooted__context > div > img {
    height: 200px;
    object-fit: contain;
  }

  .rooted__context > div {
    display: flex;
    align-items: center;
  }

  .rooted__context > div > h5 {
    max-width: 80%;
    font-weight: 400;
  }

  .cs-two {
    background-color: #F6F6F6;
    margin-top: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .cs-two__image {
    width: 100%;
    object-fit: cover;
  }

  .cs-two .col-lg-3 {
    border-left: solid 2px #FFFFFF;
  }

  .callouts__list > ol > li {
    list-style: numbers !important;
    padding: 5px 0px;
    font-size: 20px;
    font-weight: lighter;
  }

  .callouts__list > h5 {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 600;
  }

  .callouts {
    padding: 100px 0px;
  }

  .cs-three {
    padding: 100px 0px;
  }

  .cs-three__img {
    height: 300px;
    object-fit: cover;
  }

  .cs-three__article {
    height: 350px;
  }

  .cs-small-text {
    font-size: 14px !important;
    padding-left: 15px;
    line-height: 33px;
    letter-spacing: 0.17px;
    font-style: italic;
    padding-top: 10px;
  }

  .cs-five {
    padding: 100px 0px;
  }

  .cs-five .col-lg-9 .col-md-6 .cs-up-arrow .down-arrow, .cs-five .col-lg-9 .col-md-6 .cs-down-arrow .down-arrow {
    transform: rotate(180deg);
  }

  .cs-five .col-lg-9 .col-md-6 .cs-up-arrow, .cs-five .col-lg-9 .col-md-6 .cs-down-arrow {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .cs-five .col-lg-9 .col-md-6 {
    background-color: #F6F6F6;
    max-width: 300px;
    margin: 10px auto;
    text-align: center;
    padding: 30px;
  }

  .cs-five .col-lg-9 .col-md-6 h1 {
    font-size: 50px;
    font-weight: 300;
    letter-spacing: 0.87px;
    line-height: 33px;
    color: #4A4BC7;
    font-style: italic;
  }

  .cs-up-arrow > h1, .cs-down-arrow > h1 {
    padding-right: 20px;
  }

  @media (max-width: 991px) {
    .rooted {
      border-left: none;
    }

    .rooted__main {
      padding: 150px 20px 50px;
    }

    .rooted__details {
      padding: 0px 15px 50px 50px;
    }

    .rooted__store {
      padding: 20px 0px;
    }

    .rooted__tools {
      padding: 50px 0px 0px 0px;
    }

    .rooted__context > div > img {
      height: 60px;
      object-fit: contain;
    }

    .rooted__context > div > h5 {
      max-width: 100%;
      font-weight: 400;
    }

    .cs-two__image {
      width: 100%;
      object-fit: cover;
    }

    .cs-two > .container > .row {
      display: flex;
      flex-direction: column-reverse;
    }

    .callouts > .container > .row {
      display: flex;
      flex-direction: column-reverse;
    }

    .cs-three__img {
      height: 100px;
      object-fit: cover;
    }

    .cs-three__article {
      height: 100px;
    }

    .rooted__herotext {
      display: flex;
      text-align: center;
      padding: 0px 10px 50px;
    }

    .rooted__herotext > h5 {
      width: 35%;
    }

    .rooted__title {
      padding-left: 0px;
    }
  }
`